<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">用户列表</span>
		</div>
		<div class="bg-white p-2 m-2">
			<div class="d-flex align-items-center">
				<div class="d-flex align-items-center m-1">
					<el-input style="width: 200px" size="small" v-model="search.keyword" placeholder="请输入搜索内容" clearable
						@clear="toSearch" @keyup.enter.native="toSearch">
					</el-input>
					<el-button class="ml-1" @click="toSearch" type="primary" size="mini" icon="el-icon-search">
					</el-button>
				</div>
			</div>
			<el-table border class="mt-3" :data="lists.data" style="width: 100%">
				<el-table-column align="center" width="120" prop="user_id" label="用户ID"></el-table-column>
				<el-table-column align="center" label="头像" width="90">
					<template slot-scope="scope">
						<viewer>
							<img style="width: 45px;height: 45px;" :src="scope.row.avatarUrl">
						</viewer>
					</template>
				</el-table-column>
				<el-table-column align="center" label="昵称">
					<template slot-scope="scope">
						<span style="">{{scope.row.nickName}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="mobile" label="手机号"></el-table-column>
				<el-table-column align="center" prop="user_prize_count" label="抽奖数">
					<template slot-scope="scope">
						<el-button type="text" size="mini" @click="openUserPrize(scope.row)">
							{{scope.row.user_prize_count}}
						</el-button>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="prize_record_count" label="中奖数"></el-table-column>
				<el-table-column align="center" label="福卡数">
					<template slot-scope="scope">
						<el-button type="text" size="mini" @click="openUserFocas(scope.row)">
							{{scope.row.user_focas_count}}
						</el-button>
					</template>
				</el-table-column>
				<el-table-column align="center" label="加入时间" width="180">
					<template slot-scope="scope">
						<span style="">{{scope.row.create_time}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作" width="170">
					<template slot-scope="scope">
						<el-button type="text" size="mini" @click="deleteItem(scope.row)" class="table-last-btn">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="height: 30px;" class="mt-2 px-2">
				<el-pagination :page-size="lists.per_page" :current-page.sync="search.page"
					style="display: inline-block;float: right;" background @current-change="pageChange"
					layout="prev, pager, next" :total="lists.total">
				</el-pagination>
			</div>
			<el-dialog title="福卡数" :visible.sync="userFocasVisible" top="3vh" width="650px" height="60%">
				<el-table border class="mt-3" :data="userFocasList" style="width: 100%">
					<el-table-column align="center" prop="name" label="名称"></el-table-column>
					<el-table-column align="center" label="福卡图">
						<template slot-scope="scope">
							<viewer>
								<img style="width: 45px;height: 45px;" :src="scope.row.image">
							</viewer>
						</template>
					</el-table-column>
					<el-table-column align="center" prop="total_count" label="总数量"></el-table-column>
					<el-table-column align="center" prop="use_count" label="已使用"></el-table-column>
					<el-table-column align="center" prop="unused_count" label="未使用"></el-table-column>
				</el-table>
			</el-dialog>
			<el-dialog title="抽奖数" :visible.sync="userPrizeVisible" top="3vh" width="800px" height="60%">
				<el-table border class="mt-3" :data="userPrizeList.data" style="width: 100%">
					<el-table-column align="center" width="120" prop="user_prize_id" label="ID"></el-table-column>
					<el-table-column align="center" label="是否中奖">
						<template slot-scope="scope">
							<el-tag size="mini" v-if="scope.row.is_prize == 10">未中奖</el-tag>
							<el-tag size="mini" type="danger" v-if="scope.row.is_prize == 20">中奖</el-tag>
						</template>
					</el-table-column>
					<el-table-column align="center" label="奖品">
						<template slot-scope="scope">
							<el-tag size="mini" v-if="scope.row.is_prize == 10">未中奖</el-tag>
							<template v-else>
								<div class="d-flex">
									<div>
										<viewer>
											<img style="width: 45px;height: 45px;" :src="scope.row.prize.image">
										</viewer>
									</div>
									<div class="ml-2">
										{{scope.row.prize.name}}
									</div>
								</div>
							</template>
						</template>
					</el-table-column>
					<el-table-column align="center" label="抽奖时间" width="180">
						<template slot-scope="scope">
							<span style="font-weight: 800;">{{scope.row.create_time}}</span>
						</template>
					</el-table-column>
				</el-table>
				<div style="height: 30px;" class="mt-2 px-2">
					<el-pagination :page-size="userPrizeList.per_page" :current-page.sync="searchUserPrize.page"
						style="display: inline-block;float: right;" background @current-change="pageUserPrizeChange"
						layout="prev, pager, next" :total="userPrizeList.total">
					</el-pagination>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['layout'],
		data() {
			return {
				lists: [],
				userFocasVisible: false,
				userPrizeVisible: false,
				search: {
					s: 'store/user/index',
					keyword: '',
					page: 1,
				},
				userFocasList: [],
				userPrizeList: [],
				searchUserFocas: {
					s: 'store/data.user_focas/userFocasList',
					user_id: 0,
				},
				searchUserPrize: {
					s: 'store/data.user_prize/index',
					user_id: 0,
					page: 1,
				},
			}
		},
		created() {
			this.getList();
		},
		methods: {
			// 分页
			pageUserPrizeChange(page) {
				this.searchUserPrize.page = page;
				this.openUserFocas();
			},
			openUserFocas(data) {
				this.searchUserFocas.user_id = data.user_id
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.searchUserFocas,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.userFocasList = res.data.data
					this.userFocasVisible = true
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			openUserPrize(data) {
				this.searchUserPrize.user_id = data.user_id
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.searchUserPrize,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.userPrizeList = res.data.data
					this.userPrizeVisible = true
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			toSearch() {
				this.search.page = 1;
				this.getList()
			},
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			// 删除单个
			deleteItem(data) {
				this.$confirm('是否要删除该用户吗?', '提示', {
					confirmButtonText: '删除',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.layout.showLoading()
					this.axios({
						token: true,
						params: {
							s: 'store/user/delete',
							user_id: data.user_id
						},
						method: 'get',
					}).then(res => {
						if (res.data.code == 1) {
							this.$message({
								message: res.data.msg,
								type: 'success'
							});
							this.getList()
						}
						this.layout.hideLoading()
					}).catch(err => {
						this.layout.hideLoading()
					})
				})
			},
		},
	}
</script>

<style>

</style>
